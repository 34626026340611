<template>
  <div class="gameView" ref="myRef">
    <div class="item item_one" @click="navigetList('lan')">
      <div class="item_img">
        <img src="../assets/image/icon/g1.png" alt="" />
      </div>
      <div class="item_text">
        <span>本地游戏</span>
        <label>支持所在店面组队游戏竞技</label>
      </div>
    </div>
    <div class="item item_two" @click="navigetList('wan')">
      <div class="item_img">
        <img src="../assets/image/icon/g2.png" alt="" />
      </div>
      <div class="item_text">
        <span>跨店联机游戏</span>
        <label>支持多店面联机组队游戏竞技</label>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const myRef = ref(null);
    const router = useRouter();
    onMounted(() => {
      myRef.value.style.height =
        `${document.documentElement.clientHeight}` + "px"; //动态设置HTML元素高度
    });
    const navigetList = (val) => {
      if (val === "lan") {
        router.push({ path: "game", query: { type: val } });
      } else if (val === "wan") {
        router.push({ path: "game", query: { type: val } });
      }
    };
    return {
      myRef,
      navigetList,
    };
  },
};
</script>

<style lang="scss" scoped>
.gameView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #fff;
  padding: 0 13px;
  box-sizing: border-box;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 44px 35px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .item_text {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 35px;
      span {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      label {
        font-size: 15px;
      }
    }
  }
  .item_one {
    background: url("../assets/image/bg2.png") no-repeat;
    background-size: 100% 100%;
    .item_img {
      width: 49px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .item_two {
    background: url("../assets/image/bg1.png") no-repeat;
    background-size: 100% 100%;
    .item_img {
      width: 43px;
      height: 43px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
